
















































import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import User from '@/models/User'
import Modal from '@/components/Modal.vue'
import ChatLink from '@/components/ChatLink.vue'

@Component({
  components: { Modal, ChatLink },
  computed: {
    ...mapState('user', ['me']),
    ...mapState('welcome', ['payerWelcomeModal']),
  },
})
export default class WelcomeModal extends Vue {
  payerWelcomeModal!: boolean
  me!: User

  onHide() {
    this.$store.dispatch('welcome/closePayerWelcomeModal')
  }

  get showDispenserBullet() {
    // Only prime horizon customers get the premium dispenser
    // eslint-disable-next-line camelcase
    return this.me?.cohorts?.horizon_bcbs
  }
}
