import { render, staticRenderFns } from "./PayerWelcomeModal.vue?vue&type=template&id=090371a6&scoped=true&"
import script from "./PayerWelcomeModal.vue?vue&type=script&lang=ts&"
export * from "./PayerWelcomeModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "090371a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { BButton } from 'bootstrap-vue'
import { BImg } from 'bootstrap-vue'
import { BLink } from 'bootstrap-vue'
import IconLivechatSmall from '@/assets/icons/icon-livechat-small.svg'
import IconPhoneSmallOutline from '@/assets/icons/icon-phone-small-outline.svg'
installComponents(component, {BButton,BImg,BLink,IconLivechatSmall,IconPhoneSmallOutline})
